import React from 'react'
import { Helmet } from 'react-helmet'
import * as PropTypes from 'prop-types'
import Layout from '../../components/layoutDevelopers'
import Config from '../../config'

const REDIRECT_URL = `${Config.DEVELOPERS_PORTAL_URL}/reference/merchant-api/v1`

export default function Developers (props) {
  return (
    <Layout isLandingPage>
      <Helmet>
        <meta httpEquiv='refresh' content={`0; url=${REDIRECT_URL}`} />
        <link rel='canonical' href={REDIRECT_URL} />
      </Helmet>
      <section>
        <div className='row align-center justify-center' style={{ minHeight: 400 }}>
          <div className='col text-center'>
            <div className="inner">
              <h1>Developers</h1>
              <p>This page has moved. This page will be redirected in a few seconds.</p>
              <a className='button-link button-link-brand' href={REDIRECT_URL}>Navigate To Page Now</a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

Developers.propTypes = {
  data: PropTypes.shape({
    allApisJson: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          slug: PropTypes.string.isRequired
        })
      })).isRequired
    }).isRequired
  }).isRequired
}
